<template>
  <div :class="['mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16', { 'items-center': type !== 'upload', 'items-start': type === 'upload' }]">
    <p class="font-medium text-grey-400 max-w-sm w-full">{{ label }}</p>
    <div class="mt-4 sm:mt-0 sm:col-span-2">
      <el-date-picker v-if="type === 'date'" :readonly="readonly" class="max-w-lg shadow-sm sm:max-w-xs" />

      <el-upload v-else-if="type === 'upload'" class="max-w-lg shadow-sm" />

      <el-input
        v-else
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        class="max-w-lg shadow-sm sm:max-w-xs"
        input-class="rounded"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script>
const ElInput = () => import('@/components/shared/ELInput')
const ElDatePicker = () => import('@/components/shared/ELDatePicker')
const ElUpload = () => import('@/components/shared/ELUpload')

export default {
  name: 'ProductTableInputRow',

  components: { ElInput, ElDatePicker, ElUpload },

  props: {
    type: { type: String, default: 'text' },
    placeholder: String,
    label: String,
    value: { type: [String, Number, Boolean, Date], default: '' },
    readonly: { type: Boolean, default: false }
  }
}
</script>
