<template>
  <div v-if="userData.sanity_data">
    <!-- header -->
    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">Company settings</p>
        <p class="mt-4 text-grey-400">Here is your account info that we have on file. Contact us to change this.</p>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- display name -->
    <table-input-row v-model="userData.sanity_data.displayName" label="Display name" placeholder="Display name" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- consultant -->
    <table-input-row v-model="userData.sanity_data.consultant.name" label="Consultant" placeholder="Yvonne Lauritsen" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- contact person -->
    <table-input-row v-model="userData.sanity_data.contactPersonName" label="Contact Person" placeholder="Some Name" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- contact person email -->
    <table-input-row v-model="userData.sanity_data.contactPersonEmails[0]" label="Contact Person Email" placeholder="SomeName@company.org" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- status -->
    <table-input-row v-model="userData.sanity_data.active" label="Status" placeholder="Inactive" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- company logo -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-center">
      <p class="font-medium text-grey-400 max-w-sm w-full">Company logo</p>
      <div class="flex items-center mt-4 sm:mt-0 sm:col-span-2">
        <span class="h-48 w-48 flex justify-center rounded-full overflow-hidden bg-grey-100">
          <span class="icon-avatar-placeholder text-3xl text-grey-200" />
        </span>
        <el-button class="ml-20">Change</el-button>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">Bank information</p>
        <p class="mt-4 text-grey-400">We will payout using the information provided below.</p>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- registreringsnr -->
    <table-input-row v-model="userData.sanity_data.registrationNumber" label="Registreringsnr." readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- kontonr. -->
    <table-input-row v-model="userData.sanity_data.accountNumber" label="Kontonr." readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- andent -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16 items-start">
      <p class="font-medium text-grey-400 max-w-sm w-full">Andend</p>
      <div class="flex items-center mt-4 sm:mt-0 sm:col-span-2">
        <el-textarea
          v-model="userData.sanity_data.otherPaymentInfo"
          class="w-full"
          textarea-class="rounded shadow-sm max-w-lg"
          rows="3"
          readonly
          description="IBAN / SWIFT or similar."
        />
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">Company address</p>
        <p class="mt-4 text-grey-400">You get emails here. Contact support to change.</p>
      </div>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- legal company name -->
    <table-input-row v-model="userData.sanity_data.companyName" label="Legal Company Name" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- street address -->
    <table-input-row v-model="userData.sanity_data.companyAddress" label="Street address" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- city -->
    <table-input-row v-model="userData.sanity_data.companyCity" label="City" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- company phone -->
    <table-input-row v-model="userData.sanity_data.companyPhone" label="Company Phone" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- company vat -->
    <table-input-row v-model="userData.sanity_data.companyCvr" label="Company VAT" readonly />

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- zip -->
    <table-input-row v-model="userData.sanity_data.companyZip" label="ZIP" readonly />
  </div>
</template>

<script>
import TableInputRow from '@/components/product/TableInputRow'
import ElButton from '@/components/shared/ELButton'
import ElTextarea from '@/components/shared/ELTextarea'

export default {
  name: 'AdvertiserSettings',

  components: { TableInputRow, ElButton, ElTextarea },

  props: {
    userData: Object,

    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>
